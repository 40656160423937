.label {
  width: auto;
  overflow: visible;
  text-align: center;
  font-size: 14px;
  position: relative;
  transform: translate(-50%, 0);
}
.label > div {
  background: #376fd0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: left;
  max-width: 215px;
}

.label.short > div {
  max-width: unset;
}
.label.short .short-count {
  text-align: right;
}

.label.short.none {
  display: none !important;
}

.short-label > div:first-of-type {
  display: flex;
}

.short-label > div:first-of-type > div:first-of-type {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.label > div > hr {
  height: 1px;
  border-color: #fff;
}

.location-popup {
  min-width: 150px;
}
.location-popup h3 {
  padding: 0;
  margin: 0 0 5px 0;
}

.location-popup > div {
  font-size: 0;
}

.location-popup > div > div {
  font-size: 12px;
}
.location-popup > div > div + div {
  margin-top: 5px;
}

.gm-style-iw > button {
  display: none !important;
}

.identifier-table {
  width: 100%;
}
.identifier-table td {
  white-space: nowrap;
}

.identifier-table td:not(:first-child),
.identifier-table th:not(:first-child) {
  padding-left: 6px;
}
.identifier-table td:not(:last-child),
.identifier-table th:not(:last-child) {
  padding-right: 6px;
}
